<template>
    <div class="container" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="wrap">
            <p>知识点结构比例

                <el-button size="small" @click="add()" type="primary" style="float: right;">新增</el-button>
            </p>

            <el-table :data="tableData" border size="small" show-summary>
                <el-table-column prop="path" label="知识点名称">
                    <!-- slot-scope="scope" -->
                    <template slot-scope="scope">

                        <div style="width: 100%; display: flex;justify-content: space-around;align-items: center;">
                            <el-cascader ref="cascader" stripe clearable :props="defaultProps" v-model="scope.row.path"
                            :options="option" @visible-change="handleChange(scope.$index, scope.row,$event)" style="width:90%;" :disabled="!editFlag"
                            popper-class="mycascader">
                           </el-cascader>
                           <!-- <div style="display: flex;flex-direction: column;margin-left: 20px;width: 30%;" >
                              <span v-for="(item,index) in scope.row.knowCount" :key="index" style="margin-bottom: 5px;">{{item.knowledgeName}}:{{item.count}}题</span>
                           </div> -->
                        </div>


                        

                    </template>
                </el-table-column>
                <el-table-column prop="rate" label="占比" width="120">
                    <template slot-scope="scope">
                        <el-input v-model.number="scope.row.rate" placeholder="请输入内容" :disabled="!editFlag"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">

                        <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="wrap">
            <p>选择题型</p>
            <div class="content">
                <div class="item">
                    <el-form ref="form" :model="form1" label-width="80px">
                        <el-form-item label="单选题">
                            每题
                            <el-input-number v-model="form1.score" controls-position="right" :min="0" :step="0.1" :step-strictly="true"
                                style="width: 120px;" placeholder="0"></el-input-number>
                            分
                            
                            <span>共<i class="enlarge">{{getAllSingle(form1).allQuestion}}</i>题,满分<i class="enlarge">{{getAllSingle(form1).allScore}}</i>分</span>
                        </el-form-item>
                        <el-form-item label="困难">
                            <el-input-number v-model="form1.level5" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="较难">
                            <el-input-number v-model="form1.level4" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="一般">
                            <el-input-number v-model="form1.level3" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="较易">
                            <el-input-number v-model="form1.level2" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="容易">
                            <el-input-number v-model="form1.level1" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>

                    </el-form>
                </div>
                <div class="item">
                    <el-form ref="form" :model="form2" label-width="80px">
                        <el-form-item label="多选题">
                            每题 <el-input-number v-model="form2.score" controls-position="right" :min="0" :step="0.1"  :step-strictly="true"
                                style="width: 120px;" placeholder="0"></el-input-number>分
                                <span>共<i class="enlarge">{{getAllSingle(form2).allQuestion}}</i>题,满分<i class="enlarge">{{getAllSingle(form2).allScore}}</i>分</span>
                        </el-form-item>
                        <el-form-item label="困难">
                            <el-input-number v-model="form2.level5" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="较难">
                            <el-input-number v-model="form2.level4" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="一般">
                            <el-input-number v-model="form2.level3" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="较易">
                            <el-input-number v-model="form2.level2" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="容易">
                            <el-input-number v-model="form2.level1" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>

                    </el-form>
                </div>
                <div class="item">
                    <el-form ref="form" :model="form3" label-width="80px">
                        <el-form-item label="判断题">
                            每题 <el-input-number v-model="form3.score" controls-position="right" :min="0" :step="0.1"  :step-strictly="true"
                                style="width: 120px;" placeholder="0"></el-input-number>分
                                <span>共<i class="enlarge">{{getAllSingle(form3).allQuestion}}</i>题,满分<i class="enlarge">{{getAllSingle(form3).allScore}}</i>分</span>
                        </el-form-item>
                        <el-form-item label="困难">
                            <el-input-number v-model="form3.level5" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="较难">
                            <el-input-number v-model="form3.level4" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="一般">
                            <el-input-number v-model="form3.level3" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="较易">
                            <el-input-number v-model="form3.level2" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>
                        <el-form-item label="容易">
                            <el-input-number v-model="form3.level1" controls-position="right"
                                :min="0" :step="1" :step-strictly="true" placeholder="0"></el-input-number>
                        </el-form-item>

                    </el-form>
                </div>
            </div>
        </div>
        <p style="text-align: right;padding-right: 100px;">本次组卷共<span
                class="important">{{ getAll().allQuestion }}</span>题,满分<span
                class="important">{{ getAll().allScore }}</span>分</p>
        <p style="text-align: right;padding-right: 100px;">
            <el-button type="primary" @click="generatePaper()">预生成试卷</el-button>
        </p>
        <el-dialog title="试卷分析" :visible.sync="dialogVisible" width="60%" :close-on-click-modal="false">
            <div class="paper-analyse">
                <paper-analysis :examId="paperId"></paper-analysis>
            </div>
            <span slot="footer" class="dialog-footer" style="text-align: center;">
                <el-button type="primary" @click="goPaper()">进入试卷</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import PaperAnalysis from './PaperAnalysis.vue';

export default {
    name: 'AutoVolumes',
    data() {
        return {
            fullscreenLoading: false,
            option: [],
            tableData: [],
            value: '',
            defaultProps: {
                value: 'path',
                label: 'name',
                expandTrigger: 'hover',
                multiple: true,
                checkStrictly: true
            },
            input: '',
            editFlag: true,
            form1: {
                score: '',
                level1: '',
                level2: '',
                level3: '',
                level4: '',
                level5: ''
            },
            form2: {
                score: '',
                level1: '',
                level2: '',
                level3: '',
                level4: '',
                level5: ''
            },
            form3: {
                score: '',
                level1: '',
                level2: '',
                level3: '',
                level4: '',
                level5: ''
            },
            paperId: "",
            dialogVisible: false


        };
    },
    created() {
        this.getKnowledgeList();
    },
    components:{PaperAnalysis},
    methods: {

        getAll() {
            let question1 = Number(this.form1.level1 ? this.form1.level1 : 0) + Number(this.form1.level2  ? this.form1.level2 : 0) + Number(this.form1.level3 ? this.form1.level3 : 0) + Number(this.form1.level4 ? this.form1.level4 : 0) + Number(this.form1.level5 ? this.form1.level5 : 0)
            let question2 = Number(this.form2.level1 ? this.form2.level1 : 0) + Number(this.form2.level2  ? this.form2.level2 : 0) + Number(this.form2.level3 ? this.form2.level3 : 0) + Number(this.form2.level4 ? this.form2.level4 : 0) + Number(this.form2.level5 ? this.form2.level5 : 0)
            let question3 = Number(this.form3.level1 ? this.form3.level1 : 0) + Number(this.form3.level2  ? this.form3.level2 : 0) + Number(this.form3.level3 ? this.form3.level3 : 0) + Number(this.form3.level4 ? this.form3.level4 : 0) + Number(this.form3.level5 ? this.form3.level5 : 0)
            let allQuestion = question1 + question2 + question3;
            let allScore = question1 * (this.form1.score ? this.form1.score:0) + question2 * (this.form2.score ? this.form2.score:0) + question3 * (this.form3.score ? this.form3.score:0)
            return {
                allQuestion,
                allScore
            }
        },

        getAllSingle(form) {
            let allQuestion= Number(form.level1 ? form.level1 : 0) + Number(form.level2  ? form.level2 : 0) + Number(form.level3 ? form.level3 : 0) + Number(form.level4 ? form.level4 : 0) + Number(form.level5 ? form.level5 : 0)
            let allScore = allQuestion* (form.score ? form.score:0)
            return {
                allQuestion,
                allScore
            }
        },
        async getKnowledgeList() {
            // 获取知识点树
            const result = await this.Api.databaseManage.getKnowledgeTree({});
            this.removeEmptyChildren(result.data);
            result.data.forEach(element => {
                element.children.forEach(child => {
                    child.children.forEach(el => {
                        el['disabled'] = false;
                    })
                });
            });
            this.$nextTick(() => {
                this.option = result.data;
                console.log("🚀 ~ getKnowledgeList ~ this.options:", this.option)
            })

        },
        goPaper() {
            this.$router.push(`/admin/eidt-question/${this.paperId}`);
        },
        removeEmptyChildren(data) {
            for (let i = 0; i < data.length; i++) {
                let node = data[i];
                if (node.children && node.children.length === 0) {
                    node['disabled'] = true;
                    delete node.children;
                } else if (node.children && node.children.length > 0) {
                    node['disabled'] = true;
                    this.removeEmptyChildren(node.children);
                }
            }
        },
        handleChange(index,row,flag) {
            if(!flag){
                console.log("🚀 ~ handleChange ~ index,row:", index,row)
                // this.getKnowledgeCount(index,row)
            }
            
        },
        async getKnowledgeCount(index,row){
            let path=[];
            row.path.forEach(el => {
                path.push(el[el.length-1]);
            })
            console.log("🚀 ~ getKnowledgeCount ~ path:", path)
             let paths={
                paths:path
             }
            const result = await this.Api.databaseManage.getQuestionCount(paths);
            this.tableData[index]['knowCount']=result.data
            console.log("🚀 ~ getKnowledgeCount ~ this.tableData:", this.tableData)
            console.log("🚀 ~ getKnowledgeCount ~ result:", result)
        },
        add() {
            let obj = { path: [], rate: 0 ,knowCount:[]};
            this.tableData.push(obj);
            this.editFlag = true;
        },
        edit() {
            this.editFlag = !this.editFlag;
        },
        handleDelete(i, row) {
            this.tableData.splice(i, 1);
        },
        async generatePaper() {
            let rate = 0;
            let knowledge = this.tableData.map(item => {
                let path = [];
                item.path.forEach(el => {
                    path.push(el[el.length - 1]);
                })
                rate += item.rate;
                return { paths: path, rate: item.rate }
            });
            if (rate != 100) {
                this.$message.error("知识点比例总和必须为100%");
                return;
            }
            console.log("🚀 ~ knowledge ~ knowledge:", knowledge)
            let data = {
                "knowledgeRate": knowledge,
                "quemsg": [
                    {
                        "type": 1,
                        "score": Number(this.form1.score?this.form1.score:0),
                        "difmsg": [
                            { "difficulty": 1, "num": Number(this.form1.level1?this.form1.level1:0) },
                            { "difficulty": 2, "num": Number(this.form1.level2?this.form1.level2:0) },
                            { "difficulty": 3, "num": Number(this.form1.level3?this.form1.level3:0) },
                            { "difficulty": 4, "num": Number(this.form1.level4?this.form1.level4:0) },
                            { "difficulty": 5, "num": Number(this.form1.level5?this.form1.level5:0) }]
                    },
                    {
                        "type": 2,
                        "score": Number(this.form2.score?this.form2.score:0),
                        "difmsg": [
                            { "difficulty": 1, "num": Number(this.form2.level1?this.form2.level1:0) },
                            { "difficulty": 2, "num": Number(this.form2.level2?this.form2.level2:0) },
                            { "difficulty": 3, "num": Number(this.form2.level3?this.form2.level3:0) },
                            { "difficulty": 4, "num": Number(this.form2.level4?this.form2.level4:0) },
                            { "difficulty": 5, "num": Number(this.form2.level5?this.form2.level5:0) }]
                    },
                    {
                        "type": 3,
                        "score": Number(this.form3.score?this.form3.score:0),
                        "difmsg": [
                            { "difficulty": 1, "num": Number(this.form3.level1?this.form3.level1:0) },
                            { "difficulty": 2, "num": Number(this.form3.level2?this.form3.level2:0) },
                            { "difficulty": 3, "num": Number(this.form3.level3?this.form3.level3:0) },
                            { "difficulty": 4, "num": Number(this.form3.level4?this.form3.level4:0) },
                            { "difficulty": 5, "num": Number(this.form3.level5?this.form3.level5:0) }]
                    },
                ]
            }
            this.fullscreenLoading = true;
            try {
                const result = await this.Api.databaseManage.prePaper(data);
                // 1790622630502035457
                this.fullscreenLoading = false;
                if (result.code != 200) {
                    this.$message.error(result.message);
                    return;
                }
                this.paperId = result.data;
                this.getPaper();

            } catch (error) {
                this.$message.error(error);
                this.fullscreenLoading = false;

            }


        },
        async getPaper() {
            // const result = await this.Api.databaseManage.getPaper({ id: this.paperId });
            this.dialogVisible = true;


        }

    }
};
</script>

<style scoped lang="scss">
.enlarge{
    font-style: normal;
    font-size: 18px;
    color: red;
    font-weight: 600;
}
.container {
    width: 100%;
    overflow: hidden;
    padding: 20px;

    .wrap {
        width: calc(100% - 40px);
        overflow: hidden;

        .content {
            display: flex;
        }
    }


}
.paper-analyse{
    max-height: 500px;
    overflow-y: auto;
}
.important {
    font-size: 24px;
    font-weight: 600;
    color: red;
}

::v-deep .mycascader {
    max-height: 500px;
}

::v-deep .el-dialog__footer{
    text-align: center;
}
::v-deep .wrap {
    //   margin: 0 20px;
    //   overflow: hidden;

    .el-table--small .el-table__cell {
        text-align: center;
    }

    .el-table__header tr th {
        background-color: #f2f2f2;
        color: #000;
        /* 设置表头单元格的背景色 */
    }

    .el-input__inner {
        text-align: center;
    }

}
</style>